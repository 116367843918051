import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { storage } from 'src/util/storage';
import { resolve } from 'src/util/resolve';
import { environment } from 'src/environments/environment';

export interface Autorizacao {
  access_token?: string;
  cpf?: string;
  ds_email?: string;
  email?: string;
  foto?: string;
  nome?: string;
  nu_cpf?: string;
  telefone?: string;
  tokenGovBR?: string;
  parceiros?: string;
  permissoes?: any;
  perfis?: [];
}

@Injectable({
  providedIn: 'root'
})
export class AutorizacaoService {
  public autorizacao$: ReplaySubject<Autorizacao>;
  public logout$: ReplaySubject<boolean>;
  public forbidden$: ReplaySubject<boolean>;

  constructor(private http: HttpClient) {
    this.autorizacao$ = new ReplaySubject(1);
    this.logout$ = new ReplaySubject(1);
    this.forbidden$ = new ReplaySubject(1);
    this.autorizacao$.pipe(mergeMap(autorizacao => storage.set('autorizacao', autorizacao))).subscribe();
    from(storage.get('autorizacao'))
      .pipe(mergeMap(autorizacao => autorizacao ? this.usar(autorizacao) : of(null)))
      .subscribe();
  }

  public obterUriLoginUnico(): string {
    return resolve('govbr://auth', {
      redirectUri: environment.govbrRedirectUri,
      clientId: environment.govbrClientId,
      nonce: environment.app
    });
  }

  obter(code: string): Observable<Autorizacao> {
    const url = resolve('sisrede://login');
    return this
      .http
      .post<Autorizacao>(url, {
        code,
        redirectUriGovBR: environment.govbrRedirectUri
      }, {observe: 'response', 
      headers:{
        ["Access-Control-Allow-Origin" ]: "*"
      }
    })
      .pipe(
        catchError(err => {
          if (err.status === 401 || err.status === 400) {
            return throwError(new Error('Não foi possível autenticar-se usando as credenciais fornecidas.'));
          }
          return throwError(new Error('Não foi possível autenticar-se. Tente novamente mais tarde.'));
        }))
      .pipe(
        map((response) => {
          if (response instanceof HttpResponse) {
            const token = response.headers.get('Authorization');
            const usuario = response.body;
            usuario.access_token = token;
            return usuario;
          }
        })
      );
  }


  usar(autorizacao: Autorizacao): Observable<Autorizacao> {
    this.autorizacao$.next(autorizacao);
    return of(autorizacao);
  }

  setAuth(autorizacao: Autorizacao) {
    return storage.set('autorizacao', autorizacao);
  }

  async autorizacao(): Promise<Autorizacao> {
    return storage.get('autorizacao');
  }
}
