import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { CertificadoLayout } from 'src/app/interfaces/certificado-layout';
import { CertificadoLayoutArquivo } from 'src/app/interfaces/certificado-layout-arquivo';
import { TurmaInscrito } from 'src/app/interfaces/turmaInscrito';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { PageData } from '../certificado-pdf/certificado-pdf.page';

@Component({
  selector: 'app-pdf-page',
  templateUrl: './pdf-page.component.html',
  styleUrls: ['./pdf-page.component.scss'],

})

export class PdfPageComponent implements OnInit, AfterViewInit {

  obj: PageData;

  emitter: EventEmitter<void> = new EventEmitter();
  bgFrente: any;

  constructor(
    private arquivoService: ArquivoService,
  ) { }


  ngOnInit() {

    let imagenes = document.getElementsByTagName("img");
    for (let i = 0; i < imagenes.length; i++) {
      imagenes[i].setAttribute("crossorigin", "*");
    }

    let path = "assets/certificado/";
    let filename = "bgFrente.png";

    // this.file.createFile(path,filename,true);
    // this.file.writeFile(path,filename,this.obj.bgFrentre[0], {replace: true, append: true});

    // const fileNew = new File([this.obj.bgFrentre[0]]
    //   , "assets/certificado/bgFrente.png"
    //   , { type: 'image/png', lastModified: Date.now() });

    //   const formData: FormData = new FormData();
    //   formData.append('fileKey', fileNew, "/assets/certificado/bgFrente.png");

    // var blob = this.obj.bgFrentre[0];
    // var xhr = new XMLHttpRequest();
    // xhr.open("GET", './assets/certificado/bgFrente.png');
    // xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
    // xhr.onload = function () {
    //   xhr.response;
    //   var file = fileNew;
    //   console.log(file);
    // }
    // xhr.send()


  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

  textoModificado(): string {
    return this.obj.certificadoLayout.dsTexto;
  }

  getImagem(file: File): any {
    let imagem = {};
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      this.bgFrente = reader.result;
    }

  }

  getDate(mask: string): any {
    let hoje = new Date();
    let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
      "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    if ("dd" == mask)
      return hoje.getDay();
    if ("MM" == mask) {
      return meses[hoje.getMonth()];
    }
    if ("yyyy" == mask)
      return hoje.getFullYear();

    return hoje;
  }

}


