import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { toast } from 'src/util/toast';
import { mergeMap } from 'rxjs/operators';
import { PerfilPessoaService } from 'src/app/services/perfil-pessoa.service';
import { storage } from 'src/util/storage';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of, forkJoin, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissaoGuardService implements CanActivate{

  constructor(
    private route: Router,
    private autorizacaoService: AutorizacaoService,
    private perfilPessoaService: PerfilPessoaService
  ) { }

  page;

  autorizado = false;
  // funcionalidadeEspecifica = 'visualizar';
  funcionalidadeEspecifica = 'listar';

  canActivate(route: ActivatedRouteSnapshot): Observable<any> {

    this.page = route.data.page;
    if (route.data.funcionalidadeEspecifica !== undefined) {
      this.funcionalidadeEspecifica = route.data.funcionalidadeEspecifica;
    }

    // storage.set('perfil', arrayPerfil)
    const sub = forkJoin([
      from(this.perfilPessoaService.arvorePermissao()),
      from(this.autorizacaoService.autorizacao())
    ]).pipe(
      mergeMap( ([permissao, perfisUsuario]) => {
        // @ts-ignore
        if (perfisUsuario.perfis.includes('administrador')) this.autorizado = true;
        if (permissao && permissao[this.page] && permissao[this.page].includes(this.funcionalidadeEspecifica)) this.autorizado = true;
        if (!this.autorizado) {
          toast('Você não tem acesso a essa página!');
          this.route.navigate(['app/home']);
        }
        return of(this.autorizado);
      })
    );

    return sub;
  }
}
