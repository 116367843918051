import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventsProvider {

  private trailPageSubject = new Subject<any>();

  publishTrailPage(data: any) {
    this.trailPageSubject.next(data);
  }

  observeTrailPage(): Subject<any> {
    return this.trailPageSubject;
  }
}
