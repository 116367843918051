import { Component } from '@angular/core';

import { AlertController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { Router } from '@angular/router';
import { toast } from 'src/util/toast';
import { PessoaService } from 'src/app/services/pessoa.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private autorizacaoService: AutorizacaoService,
    private pessoaService: PessoaService,
    private router: Router,
    private alertCtrl: AlertController,
    private swUpdate: SwUpdate
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.autorizacaoService.forbidden$
      .subscribe((forbidden) => {
        if (forbidden) {
          toast('Você não tem acesso a essa página!');
          this.router.navigate(['app', 'home']);
        }
      });

    this.autorizacaoService.logout$
      .subscribe((logout) => {
        if (logout) {
          this.autorizacaoService.usar(null);
          this.pessoaService.usar(null);
          toast('Sessão expirada, por favor, realize login novamente.');
          this.router.navigate(['app', 'home']);
        }
      });
    this.checkForUpdate();
  }

  checkForUpdate() {
    // tslint:disable-next-line:no-console
    console.info('check for update sem refresh');
    this.swUpdate.available.subscribe(event => {
      this.confirmUpdateApp();
      // tslint:disable-next-line:no-console
      console.info('current version is ', event.current, ' available version is ', event.available);
    });
    this.swUpdate.activated.subscribe(event => {
      // tslint:disable-next-line:no-console
      console.info('old version was ', event.current, ' new version is ', event.previous);
    });
  }

  async confirmUpdateApp() {
    const alert = await this.alertCtrl.create({
      header: 'Atualizar aplicação',
      message: 'Existe uma nova versão disponível, deseja atualizar?',
      cssClass: 'alert-primary',
      buttons: [
        {
          text: 'Não',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Sim',
          handler: () => {
            window.location.href = window.location.href + '?d=' + new Date().getTime();
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }
}
