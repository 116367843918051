import { ToastController } from '@ionic/angular';
import { inject } from './inject';

const toasts: any[] = [];

const toast = async (
  message: string | any, duration: number = 3000,
  position: 'top' | 'bottom' | 'middle' = 'top',
  buttons: any = null
): Promise<any> => {
  const toastCtrl: ToastController = await inject(ToastController);
  const t = await toastCtrl.create({
    message,
    position,
    duration,
    buttons
  });
  if (!toasts.length) {
    await t.present();
  }
  toasts.push(t);
  t.onDidDismiss().then(() => {
    toasts.shift();
    if (toasts.length) {
      toasts.shift().present();
    }
  });

  return t;
};

export { toast };
