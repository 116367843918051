import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Pessoa } from 'src/app/interfaces/pessoa';
import { storage } from 'src/util/storage';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'src/util/resolve';
import { Lista } from 'src/app/interfaces/lista';
import { OrdenacaoEnum } from 'src/app/enuns/ordenacao.enum';
import { ServicoFiltrado } from 'src/app/components/popover-com-busca/interfaces/servico-filtrado';
import { HashMap } from 'src/app/interfaces/hash-map';
import * as _ from 'lodash';
import { StatusEnum } from 'src/app/enuns/status.enum';

@Injectable({
  providedIn: 'root'
})
export class PessoaService implements ServicoFiltrado {

  public pessoa$: ReplaySubject<Pessoa>;

  constructor(
    private http: HttpClient
  ) {
    this.pessoa$ = new ReplaySubject(1);
    this.pessoa$.pipe(mergeMap(pessoa => storage.set('pessoa', pessoa))).subscribe();
  }

  listar(filtros?: PessoaFiltro, page: number = 0, count: number = 30): Observable<Lista<Pessoa[]>> {
    const url = resolve('sisrede://pessoas', {page, count, ...filtros});
    return this.http.get<Lista<Pessoa[]>>(url);
  }

  filtrar(filtros?: any, page?: number, count?: number, extras?: any): Observable<Lista<HashMap[]>> {
    filtros = _.merge(filtros, { stPreCadastro: false });
    const url = resolve('sisrede://pessoas/filtros', {page, count, ...filtros});
    return this.http.get<Lista<HashMap[]>>(url);
  }

  obterPessoaCadastrada(nuCpf: string): Observable<any> {
    return this.listar({nuCpf})
      .pipe(
        map((pessoas) => {
          if (!pessoas.content.length) return null;
          return pessoas.content[0];
        })
      );
  }

  obter(id: number) {
    const url = resolve('sisrede://pessoas/id', {
      id
    });
    return this.http.get<Pessoa>(url);
  }

  pessoa(): Promise<Pessoa> {
    return storage.get('pessoa');
  }

  usar(pessoa: Pessoa): Observable<Pessoa> {
    this.pessoa$.next(pessoa);
    return of(pessoa);
  }


  cadastrar(pessoa: Pessoa): Observable<Pessoa> {
    const url = resolve('sisrede://pessoas');
    return this.http.post<Pessoa>(url, pessoa);
  }

  atualizar(id: number, pessoa: Pessoa): Observable<Pessoa> {
    const url = resolve('sisrede://pessoas/id' , { id });
    return this.http.put<Pessoa>(url, pessoa);
  }

  listarParticipantes(filtros?: PessoaFiltro, page: number = 0, count: number = 30): Observable<Lista<Pessoa[]>>  {
    const url = resolve('sisrede://pessoas/participantes', {page, count, ...filtros});
    return this.http.get<Lista<Pessoa[]>>(url);
  }
}


export interface PessoaFiltro {
  nuCpf?: string;
  noPessoa?: string;
  ordenacao?: OrdenacaoEnum;
  idsPessoa?: Array<any>;
  idsUf?: Array<any>;
  idsMunicipio?: Array<any>;
  idsOrganizacao?: Array<any>;
  idsPerfil?: Array<any>;
  stPreCadastro?: boolean;
  nuDiaExpirar?: string;
  situacao?: string;
  idsElo?: Array<any>;
  idsGrupo?: Array<any>;
  status?: StatusEnum;

}
