import { PessoaService } from './../services/pessoa.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { map, tap } from 'rxjs/operators';
import { toast } from 'src/util/toast';

@Injectable({
  providedIn: 'root'
})
export class AutenticadoGuardService implements CanActivate{

  constructor(
    private router: Router,
    private autorizacaoService: AutorizacaoService,
    private pessoaService: PessoaService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this.autorizacaoService.autorizacao(), this.pessoaService.pessoa()])
      .pipe(
        tap(([autorizado, pessoa]) => {
          if (!autorizado || !pessoa) {
            toast('Usuário não autenticado');
            return this.router.navigate(['/login']);
          }
          return true;
        }),
        map(autorizado => autorizado != null));
  }
}
