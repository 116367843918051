import { Perfil } from './../interfaces/perfil';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { resolve } from 'src/util/resolve';
import { storage } from 'src/util/storage';
import { Lista } from 'src/app/interfaces/lista';
import { PerfilPessoa } from '../interfaces/perfil-pessoa';
import { StatusEnum } from 'src/app/enuns/status.enum';
import { TipoPerfilEnum } from 'src/app/enuns/tipoPerfil.enum';

@Injectable({
  providedIn: 'root'
})
export class PerfilPessoaService {

  constructor(
    private http: HttpClient
  ) {
  }

  listar(filtros?: FiltrosPerfilPessoa, page: number = 0, count: number = 30): Observable<Lista<PerfilPessoa[]>> {
    const url = resolve('sisrede://perfis-pessoa', {page, count, ...filtros});
    return this.http.get<Lista<PerfilPessoa[]>>(url);
  }

  cadastrar(perfilPessoa: PerfilPessoa): Observable<PerfilPessoa> {
    const url = resolve('sisrede://perfis-pessoa');
    return this.http.post<PerfilPessoa>(url, perfilPessoa);
  }

  vincularPerfilCidadao(perfilPessoa: PerfilPessoa): Observable<PerfilPessoa> {
    const url = resolve('sisrede://perfis-pessoa/cidadao');
    return this.http.post<PerfilPessoa>(url, perfilPessoa);
  }

  atualizarStatus(idPessoa: number, idPerfil: number, situacao: StatusEnum): Observable<PerfilPessoa> {
    const args = {idPessoa, idPerfil, status: situacao};
    const url = resolve('sisrede://perfis-pessoa/idPessoa/perfil/idPerfil/situacao/status', args);
    return this.http.patch<PerfilPessoa>(url, {});
  }

  remover(idPessoa: number, idPerfil: number): Observable<any> {
    const url = resolve('sisrede://perfis-pessoa/idPessoa/perfil/idPerfil', {idPessoa, idPerfil});
    return this.http.delete<any>(url);
  }

  removerPorPessoa(idPessoa: number): Observable<any> {
    const url = resolve('sisrede://perfis-pessoa/pessoa/id', {id: idPessoa});
    return this.http.delete<any>(url);
  }

  perfil(): Promise<Perfil[]> {
    return storage.get('perfil');
  }

  arvorePermissao(): Promise<ArvorePermissao> {
    return storage.get('arvorePermissao');
  }

  permissaoParceiro(): Promise<[]> {
    return storage.get('permissaoParceiros');
  }
}

export interface FiltrosPerfilPessoa {
  idPerfil?: number;
  idPessoa?: number;
  tpPerfil?: TipoPerfilEnum;
}


export interface ArvorePermissao {
  eixo?: Array<any>;
  elo?: Array<any>;
  evento?: Array<any>;
  grupo?: Array<any>;
  instrumento?: Array<any>;
  meta?: Array<any>;
  metaPadrao?: Array<any>;
  organizacaoTrabalho?: Array<any>;
  parceiro?: Array<any>;
  parceiroInstrumento?: Array<any>;
  participanteEvento?: Array<any>;
  perfil?: Array<any>;
  pessoa?: Array<any>;
  termoAdesao?: Array<any>;
  unidadeGestora?: Array<any>;
}
