import { AppEnvironment, merge } from 'src/environments/environment.common';

export const production = false;


export const environment: AppEnvironment = merge({
  production: false,
  time: 20000,
  govbrClientId: 'sisrede.economia.gov.br',
  govbrClientSecret: 'IadLN4AhUAJExbtJhHFoy_vJumxp6GH5OB3um93ngpopZefH44JlXRkR7PJIO4Ll4yx8qZorKhPug6BywJw9ew',
  govbrRedirectUri: 'https://sisrede.economia.gov.br/login',
  govbrLogoutRedirectUri: 'https://sisrede.economia.gov.br/logout',
  hosts: {
    sisrede: {
      host: 'sisrede.economia.gov.br',
      protocol: 'https'
    },
    govbr: {
      host: 'sso.acesso.gov.br',
      protocol: 'https'
    }
  }
});
