import {Injectable} from '@angular/core';
import { Observable, of } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {resolve} from 'src/util/resolve';
import {Lista} from 'src/app/interfaces/lista';
import {Arquivo} from 'src/app/interfaces/arquivo';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {

  constructor(
    private http: HttpClient
  ) {
  }

  getImagem(id: number) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/JSON' });

    const url = resolve('sisrede://arquivos/download/id', {id});
    return this.http.get<any>(url,
      { headers: headers, responseType: 'blob' as 'json', observe: 'response' as 'body' }

      );
  }

  download(id: number) {
    const url = resolve('sisrede://arquivos/download/id', {id});
    return this.http.get<any>(url);
  }
 
  listar(filtros?: any, page: number = 0, count: number = 10): Observable<Lista<Arquivo[]>> {
    const url = resolve('sisrede://arquivos', {page, count, ...filtros});
    return this.http.get<Lista<Arquivo[]>>(url);
  }

  obter(id: number) {
    const url = resolve('sisrede://arquivos/id', {id});
    return this.http.get<Arquivo>(url);
  }

  remover(id: number) {
    const url = resolve('sisrede://arquivos/id', {id});
    return this.http.delete<Arquivo>(url);
  }

  atualizar(id: number, arquivo: Arquivo) {
    const url = resolve('sisrede://arquivos/id', {id});
    return this.http.put<Arquivo>(url, arquivo);
  }

  renderizar(id: number) {
    const url = resolve('sisrede://arquivos/renderizar/id', {id});
    return url;
  }

  cadastrar(arquivo: any, idTipoArquivo: number): Observable<Arquivo> {
    const url = resolve('sisrede://arquivos/tipo/id', {id: idTipoArquivo});
    return this.http.post<Arquivo>(url, arquivo);
  }

  getSize(id: number): Observable<number> {
    const url = resolve('sisrede://arquivos/size/id', {id});
    return this.http.get<any>(url).pipe(
      map((resposta) => resposta.size)
    );
  }

}
