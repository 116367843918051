import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { toast } from 'src/util/toast';
import { mergeMap } from 'rxjs/operators';
import { PerfilPessoaService } from 'src/app/services/perfil-pessoa.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { forkJoin, from, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissaoAcessoElementoEspecificoGuardService implements CanActivate{

  constructor(
    private route: Router,
    private autorizacaoService: AutorizacaoService,
    private perfilPessoaService: PerfilPessoaService
  ) { }

  page;

  autorizado = true;

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {

    this.page = route.data.page;
    const sub = forkJoin([
      from(this.perfilPessoaService.permissaoParceiro()),
      from(this.autorizacaoService.autorizacao())
    ]).pipe(
      mergeMap( ([permissaoParceiroOuInstrumento, perfisUsuario]) => {
        // @ts-ignore
        if (perfisUsuario.perfis.includes('administrador')) this.autorizado = true;
        if (permissaoParceiroOuInstrumento) {
          if (this.page === 'parceiro') {
            const idParceiro = +route.params.idParceiro;
            this.autorizado = permissaoParceiroOuInstrumento[idParceiro] !== undefined;
          } else if (this.page === 'parceiro-instrumento') {
            const idParceiro = +route.params.idParceiro;
            const idInstrumento = +route.params.idInstrumento;
            this.autorizado = true;
            // @ts-ignore
            this.autorizado = permissaoParceiroOuInstrumento[idParceiro].instrumento.includes(idInstrumento);
          }
        }
        if (!this.autorizado) {
          toast('Você não tem acesso a essa página!');
          return this.route.navigate(['app/home']);
        }
        return of(true);
      })
    );

    return sub;
  }
}
