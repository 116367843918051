import * as _ from 'lodash';

/* Formato de especificação de env. Esse é o arquivo base e deve
   conter conteúdo usado por todos os ambientes. Os ambientes específicos
   devem sobrescrever o que for necessário */


export interface AppHostConfig {
  host?: string;
  protocol?: string;
  port?: string;
  root?: string;
  unauthenticated?: boolean;
  endpoints?: {
    [name: string]: string;
  };
}

export interface ValueConfig {
  [name: string]: string | number | ValueConfig;
}

export interface AppEnvironment {
  production: boolean;
  time?: number;
  default?: string;
  app?: string;
  hosts?: {
    [name: string]: AppHostConfig
  };
  values?: ValueConfig;
  govbrClientId?: string;
  govbrClientSecret?: string;
  govbrRedirectUri?: string;
  govbrLogoutRedirectUri?: string;
  shareUri?: string;
}


export const environment: AppEnvironment = {
  production: false,
  default: 'me-sisrede',
  app: 'me-sisrede',
  hosts: {
    govbr: {
      host: '',
      protocol: 'https',
      port: '',
      root: '/',
      endpoints: {
        auth: 'authorize?response_type=code&client_id=:clientId&scope=openid+email+phone+profile&redirect_uri' +
          '=:redirectUri&nonce=:nonce&state=358578ce6728a',
        logout: 'logout?post_logout_redirect_uri=:redirectUri'
      }
    },
    sisrede: {
      host: '',
      protocol: 'https',
      port: '',
      root: '/api',
      endpoints: {
        login: 'login',
        elos: 'elos',
        'elos/id': 'elos/:id',
        eixos: 'eixos',
        'eixos/id': 'eixos/:id',
        perfis: 'perfis',
        'perfis/id': 'perfis/:id',
        pessoas: 'pessoas',
        'pessoas/id': 'pessoas/:id',
        'pessoas/filtros': 'pessoas/filtros',
        'pessoas/participantes': 'pessoas/participantes',
        ufs: 'ufs',
        naturezaJuridicas:'naturezaJuridicas',
        municipios: 'municipios',
        organizacoes: 'organizacoes',
        'organizacoes/id': 'organizacoes/:id',
        'perfis-elos': 'perfis-elos',
        'perfis-elos/id': 'perfis-elos/:id',
        'perfis-pessoa': 'perfis-pessoa',
        'perfis-pessoa/cidadao': 'perfis-pessoa/cidadao',
        'perfis-pessoa/idPessoa/perfil/idPerfil/situacao/status': 'perfis-pessoa/:idPessoa/perfil/:idPerfil/situacao/:status',
        'perfis-pessoa/idPessoa/perfil/idPerfil': 'perfis-pessoa/:idPessoa/perfil/:idPerfil',
        'perfis-pessoa/pessoa/id': 'perfis-pessoa/pessoa/:id',
        parcerias: 'parcerias',
        'parcerias-estatisticas': 'parcerias/estatisticas',
        parceiros: 'parceiros',
        'parceiros/id': 'parceiros/:id',
        'parceiros/elos': 'parceiros/elos',
        instrumentos: 'instrumentos',
        'instrumentos/id': 'instrumentos/:id',
        'instrumentos/pesquisa': 'instrumentos/pesquisa',
        'instrumentos-pessoa': 'instrumentos-pessoas',
        'instrumentos-pessoa/id': 'instrumentos-pessoas/:id',
        arquivos: 'arquivos',
        'arquivos/id': 'arquivos/:id',
        'arquivos/tipo/id': 'arquivos/tipo/:id',
        'arquivos/renderizar/id': 'arquivos/:id/renderizar',
        'arquivos/download/id': 'arquivos/:id/download',
        'arquivos/size/id': 'arquivos/:id/size',
        'parceiro-instrumentos': 'parceiro-instrumentos',
        'parceiro-instrumentos/id': 'parceiro-instrumentos/:id',
        'parceiro-instrumentos/id/situacao': 'parceiro-instrumentos/:id/situacao/:csSituacao',
        'parceiro-instrumentos/parceiro/idParceiro/instrumento/idInstrumento': 'parceiro-instrumentos/parceiro/:idParceiro/instrumento/:idInstrumento',
        'parceiro-instrumento-arquivos': 'parceiro-instrumento-arquivos',
        'parceiro-instrumento-arquivos/idArquivo/idParceiroInstrumento': 'parceiro-instrumento-arquivos/arquivo/:idArquivo/instrumento/:idParceiroInstrumento',
        'instrumento-eixos': 'instrumento-eixos',
        'termos-adesao': 'termos-adesao',
        'termos-adesao/id': 'termos-adesao/:id',
        'termos-adesao/id/situacao': 'termos-adesao/:id/situacao/:csSituacao',
        'termo-adesao-arquivos': 'termo-adesao-arquivos',
        'termo-adesao-arquivos/id': 'termo-adesao-arquivos/:id',
        'termo-adesao-arquivos/idArquivo/idTermoAdesao': 'termo-adesao-arquivos/arquivo/:idArquivo/termo-adesao/:idTermoAdesao',
        'unidades-gestoras': 'unidades-gestoras',
        'unidades-gestoras/id': 'unidades-gestoras/:id',
        'unidades-gestoras/id/situacao': 'unidades-gestoras/:idParceiroInstrumento/:idPessoa/situacao/:csSituacao',
        'perfis-unidade-gestora': 'perfis-unidade-gestora',
        'perfis-unidade-gestora/deletar' : 'perfis-unidade-gestora/:idParceiroInstrumento/pessoa/:idPessoa/perfil/:idPerfil',
        'metas/id/concluido' : 'metas/:id/concluido/:situacao',
        'metas-padrao' : 'metas-padrao',
        'metas-padrao/id' : 'metas-padrao/:id',
        'metas-instrumento' : 'metas',
        'metas-instrumento/id' : 'metas/:id',
        'metas-instrumento/id/situacao' : 'metas/:id/situacao/:situacao',
        'metas-eixos': 'metas-eixos',
        'metas-eixos/meta': 'metas-eixos/meta/:idMeta',
        'metas-eixos/id': 'metas-eixos/:id',
        'metas-eixos/meta/instrumento-eixo': 'metas-eixos/:idMeta/instrumento-eixo/:idInstrumentoEixo',
        'metas-padrao-eixos': 'metas-padrao-eixos',
        'metas-padrao-eixos/id/eixo': 'metas-padrao-eixos/:idMeta/eixo/:idEixo',
        'metas-padrao-eixos/meta-padrao': 'metas-padrao-eixos/meta-padrao/:idMetaPadrao',
        eventos: 'eventos',
        'eventos/id': 'eventos/:id',
        'eventos/id/situacao': 'eventos/:id/situacao/:csSituacao',
         pautas: 'pautas',
        'pautas/id': 'pautas/:id',
        'eventos-arquivos': 'eventos-arquivos',
        'eventos-arquivos/id': 'eventos-arquivos/:idEvento',
        'eventos-arquivos/arquivo/evento': 'eventos-arquivos/arquivo/:idArquivo/evento/:idEvento',
        grupos: 'grupos',
        'grupos/id': 'grupos/:id',
        'grupos/id/situacao': 'grupos/:id/situacao/:situacao',
        'grupo-pessoas': 'grupo-pessoas',
        'grupo-pessoas/idGrupo/idPessoa': 'grupo-pessoas/:idGrupo/:idPessoa',
        'publico-incricoes': 'publico-incricoes',
        'publico-incricoes/id': 'publico-incricoes/:id',
         participantes: 'participantes',
        'participantes/evento/pessoa': 'participantes/evento/:idEvento/pessoa/:idPessoa',
        'grupos-evento': 'grupos-evento',
        'organizadores-evento': 'organizadores-evento',
        'organizadores-evento/evento/pessoa': 'organizadores-evento/evento/:idEvento/pessoa/:idPessoa',
        'certificado-layout': 'certificado-layout',
        'certificado-layout/id': 'certificado-layout/:id',
        'certificado-layout-arquivos': 'certificado-layout-arquivos',
        'certificado-layout-arquivos/certificado-layout/id': 'certificado-layout-arquivos/certificado-layout/:idCertificadoLayout',
        'certificado-layout-arquivos/download/id': 'certificado-layout-arquivos/:idArquivo/download',
        'certificado-layout-arquivos/arquivo/certificado-layout': 'certificado-layout-arquivos/arquivo/:idArquivo/certificado-layout/:idCertificadoLayout',
        curso: 'curso',
        'curso/id': 'curso/:id',
        'curso-arquivos': 'curso-arquivos',
        'curso-arquivos/curso/id': 'curso-arquivos/curso/:idCurso',
        'curso-arquivos/download/id': 'curso-arquivos/:idArquivo/download',
        'curso-arquivos/arquivo/curso': 'curso-arquivos/arquivo/:idArquivo/curso/:idCurso',
        'curso-destinacao': 'curso-destinacao',
        'curso-destinacao/idCurso/sgDestinacao': 'curso-destinacao/:idCurso/:sgDestinacao',
        'curso-pre-requisito-resource': 'curso-pre-requisito-resource',
        'curso-pre-requisito-resource/curso/id': 'curso-pre-requisito-resource/curso/:idCurso',
        'curso-pre-requisito-resource/id': 'curso-pre-requisito-resource/:idCursoPreRequisito',
        'curso-conteudo-programatico': 'curso-conteudo-programatico',
        'curso-conteudo-programatico/id': 'curso-conteudo-programatico/:id',
        turma: 'turma',
        'turma/id': 'turma/:id',
        'turma/confirmar/id': 'turma/confirmar/:id',
        'turma/confirmar/id/email': 'turma/confirmar/:id/email',
        'turma-avaliacao': 'turma-avaliacao',
        'turma-avaliacao/id': 'turma-avaliacao/:id',
        'turma-conteudo-programatico': 'turma-conteudo-programatico',
        'turma-arquivos': 'turma-arquivos',
        'turma-arquivos/turma/id': 'turma-arquivos/turma/:idTurma',
        'turma-arquivos/download/id': 'turma-arquivos/:idArquivo/download',
        'turma-arquivos/arquivo/curso': 'turma-arquivos/arquivo/:idArquivo/curso/:idTurma',
        'turma-arquivos/arquivo/turma': 'turma-arquivos/arquivo/:idArquivo/turma/:idTurma',
        'turma-inscrito' : 'turma-inscrito',
        'turma-inscrito/id' : 'turma-inscrito/:id',
        'turma-inscrito-prerequisitos' : 'turma-inscrito-prerequisitos',
        'turma-inscrito-prerequisitos/id' : 'turma-inscrito-prerequisitos/id',
        'turma-inscrito-arquivos': 'turma-inscrito-arquivos',
        'turma-inscrito-arquivos/turma/id': 'turma-inscrito-arquivos/turma/:idTurma',
        'turma-inscrito-arquivos/download/id': 'turma-inscrito-arquivos/:idArquivo/download',
        'turma-inscrito-arquivos/arquivo/curso': 'turma-inscrito-arquivos/arquivo/:idArquivo/curso/:idTurma',
        'turma-inscrito-arquivos/arquivo/turma': 'turma-inscrito-arquivos/arquivo/:idArquivo/turma/:idTurma',
      }
    }
  },
  values: {
    'usuario-default': ''
  }
};

export function merge(...env: AppEnvironment[]): AppEnvironment {
  return _.merge({}, environment, ...env);
}
