import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PROVIDERS } from './providers';
import { AutorizacaoInterceptor } from 'src/app/interceptors/autorizacao.interceptor';
import { QuillModule } from 'ngx-quill';
import { PermissaoGuardService } from './guards/permissao-guard.service';
import { AutenticadoGuardService } from './guards/autenticado-guard.service';
import { PermissaoAcessoElementoEspecificoGuardService } from './guards/permissao-acesso-elemento-especifivo-guard.service';
import { environment } from 'src/environments/environment';
import { PdfPageComponent } from './pages/certificado/pdf-page/pdf-page.component';
import { CommonModule } from '@angular/common';
import { PdfPageComponentModule } from './pages/certificado/pdf-page/pdf-page.component.module';

@NgModule({
  declarations: [AppComponent, PdfPageComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    QuillModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    PermissaoGuardService,
    PermissaoAcessoElementoEspecificoGuardService,
    AutenticadoGuardService,
    PROVIDERS,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: HTTP_INTERCEPTORS, useClass: AutorizacaoInterceptor, multi: true
    },
  ],
  exports: [
    BrowserModule,
    CommonModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
