import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { AutorizacaoService } from 'src/app/services/autorizacao.service';
import { throwError } from 'rxjs';
import { PessoaService } from 'src/app/services/pessoa.service';

@Injectable()
export class AutorizacaoInterceptor implements HttpInterceptor {
    constructor(
      private auth: AutorizacaoService,
      private pessoaService: PessoaService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        const urlsSemAutorizacao = [ '/api/login' ];
        const isAutorizacao = urlsSemAutorizacao.findIndex(url => req.url.indexOf(url) !== -1);
        if (isAutorizacao !== -1) {
            return next.handle(req);
        }

        if (req.headers.get('x-skip-autorizacao')) {
            return next.handle(req.clone({
                headers: req.headers.delete('x-skip-autorizacao')
            }));
        }
        return this.auth.autorizacao$.pipe(
            first(),
            map((autorizacao) => {
                if (autorizacao === null) {
                    return null;
                }
                return autorizacao.access_token;
            }),
            switchMap((token) => {
                if (!token) {
                    console.error('[AutorizacaoInterceptor] - usuário não está logado');
                    return next.handle(req);
                }
                const authReq = req.clone({
                    setHeaders: {
                        Authorization: `${ token }`,
                    }
                });

                // if(!environment.production) {
                // console.log(`Bearer ${ token }`);
                // }
                return next.handle(authReq).pipe(
                  catchError((err) => {
                      if (token) {
                          if (err.status === 401) {
                              this.auth.logout$.next(true);
                          }
                          if (err.status === 403) {
                              this.auth.forbidden$.next(true);
                          }
                      }
                      return throwError(err);
                  })
                );
            })
        );
    }

}
